
























































import { Component, Vue } from 'vue-property-decorator';
import ServeItem from '@c/serveItem/ServeItem.vue';
import { GetServeList, prompttag, upprompttag } from '@/http/api/ArticleApi';
import { IndexStore } from "@/store/module/index";

@Component({
  components: {
    ServeItem,
  },
})
export default class Server extends Vue {
  private loading: boolean = false;
  private search: any = {
    text: '',
  };
  private colorList: any = []; // 会员风采
  private dataList: any = []; // 参考资料
  private forumList: any = []; // 会议论坛
  private informationList: any = []; // 会员资讯
  private introductionList: any = []; // 会员简介
  private notificationList: any = []; // 培训通知
  private standardList: any = []; // 团体标准z
  private show: boolean = false
  private userInfo: any = IndexStore.userInfo.nikeName
  private id: any = IndexStore.userInfo.id
  private tag: any = ''

  private nikeName: any = ''
  private created() {
    this.getList();
    this.getpo1Fun()
  }
  private resumeClear1(): void {
    this.show = false
  }
  private AmendSubmits(): void {
    upprompttag({
      // id:this.id,
      tag: 1
    }).then(res => {
      console.log(res);
    })
    this.show = false
  }

  // 获取首页列表数据
  private getList(): void {
    this.loading = true;
    prompttag().then(res => {
      if (res.code === '000') {
        this.tag = res.result
        if (this.tag === 2) {
          this.show = true
        }
      }
    })
    GetServeList({ keyword: '' }).then((res) => {
      this.loading = false;
      if (res.code === '000') {
        this.colorList = res.result.colorList;
        this.dataList = res.result.dataList;
        this.forumList = res.result.forumList;
        this.informationList = res.result.informationList;
        this.introductionList = res.result.introductionList;
        this.notificationList = res.result.notificationList;
        this.standardList = res.result.standardList;
      }
    });
  }
  private getpo1Fun() {
    const that = this;
    if (!IndexStore.userInfo) {
      setTimeout(() => {
        that.getpo1Fun();
      }, 1000);
    } else {
      this.userInfo = IndexStore.userInfo;
      this.nikeName = IndexStore.userInfo.nikeName;
      // this.id = IndexStore.userInfo.id;

      const userPermissions = IndexStore.userInfo.userPermissions
      window.localStorage.setItem('userPermissions', userPermissions);

    }
  }
  private listSearch(): void {
    if (this.search.text) {
      this.$router.push({ path: '/serve/search', query: { text: this.search.text } });
    }
  }
  private ServeItemFun(obj: any): void {
    if (!obj.item.url) {
      const { href } = this.$router.resolve({
        path: '/article/detail',
        query: { type: obj.column, articleId: obj.item.id },
      });
      window.open(href, '_blank');
    } else {
      window.open(obj.item.url, '_blank');
    }


  }
}
